import React, { useState, useEffect } from 'react'
import TimeEntryService from '../../services/TimeEntryService';
import MaterialTable from "material-table";
import tableIcons from "../../components/MaterialTableIcons";
import { Grid, Box } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import { userData } from '../../constants/global';

const initialFValues = {
    id: 0,
    employeeFullName: '',
    startDate: '',
    endDate: '',
    totalHours: '',
    status: ''
}

export default function TimeEntryForm(props) {
    const { submitForm, popFunction, recordObject, setOpenPopup, taskList, employeeList } = props

    const [orgTaskHours, setOrgTaskHours] = useState([]);
    const [totalHours, setTotalHours] = useState(0.0);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(0);
    const [timeEntry, setTimeEntry] = useState(null);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }


        if ('startDate' in fieldValues)
            temp.startDate = fieldValues.startDate ? "" : "This field is required."

        if ('endDate' in fieldValues)
            temp.endDate = fieldValues.endDate ? "" : "This field is required."

        temp.selectedEmployeeId = selectedEmployeeId > 0 ? "" : "Select a valid Employee";

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const setSelectedEmpId = (event) => {
        setErrors({});
        setSelectedEmployeeId(event.target.value);
        setTimeEntry(null);

        console.log("setSelectedEmployeeId: " + event.target.value);

        if (event.target.value != null && event.target.value > 0) {
            getNextPayPeriod(event.target.value);
        }
    }

    const handleSubmit = e => {
        e.preventDefault()

        if (props.popFunction === 'addTimeEntry') {
            console.log("in addTimeEntry........selectedEmployeeId: " + selectedEmployeeId);
            if (validate()) {
                timeEntry.employeeId = selectedEmployeeId;
                submitForm(timeEntry, 'addTimeEntry');
            }

            return;
        } else if (props.popFunction === 'submitForApproval') {
            submitForm(values, 'submitForApproval');
            return;
        }

        if (validate())
            submitForm(values, resetForm);

    }

    const getDailyTasks = (id) => {

        TimeEntryService.getDailyTasksByTimeEntryId(id).then(res => {
            setOrgTaskHours(res.data);
            setTotalHours(parseFloat(props.recordObject.totalHours).toFixed(1));
        });
    }

    const employeeListOptions = props.employeeList.map(
        item => {
            return {
                "id": item.id,
                "title": item.firstName + " " + item.middleName + " " + item.lastName
            }
        }
    );

    const getNextPayPeriod = (employeeId) => {

        TimeEntryService.getNextPayPeriod(employeeId).then(res => {
            setTimeEntry(res.data);
        });
    }

    const saveTimeEntry = (action) => {

        TimeEntryService.saveDailyTaskHours(orgTaskHours, props.recordObject.id, action).then(res => {
            let timeEntryObj = res.data;
            props.setOpenPopup(false);

            submitForm(timeEntryObj, 'editAndSubmitTime');
        });
    }

    const getDayOfMonth = (strDate) => {
        if (!strDate != null && strDate === "")
            return "";

        var strSplitDate = String(strDate).split(' ');
        var date = new Date(strSplitDate[0]);

        switch (date.getDay()) {
            case 0:
                return 'Sunday';
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
            default:
                return '';
        }
    }

    const getParsedDate = (strDate) => {
        if (!strDate != null && strDate === "")
            return "";

        var strSplitDate = String(strDate).split(' ');
        var date = new Date(strSplitDate[0]);

        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!

        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = mm + "-" + dd + "-" + yyyy;
        return date.toString();
    }

    useEffect(() => {

        console.log("Admin User: " + userData.adminUser);

        if (props.recordObject != null && props.recordObject.type === "Daily")
            getDailyTasks(props.recordObject.id);

        setValues({
            ...props.recordObject
        })

    }, [])

    if (props.popFunction === 'submitForApproval') {

        return (
            <Form onSubmit={handleSubmit}>
                <Box style={{ width: '600px' }}>
                    <br />
                    <div style={{ fontSize: '18px' }}>
                        {values.employeeFullName}
                        &nbsp;
                        ({getParsedDate(values.startDate)}
                        &nbsp;
                        to
                        &nbsp;
                        {getParsedDate(values.endDate)}
                        )
                    </div>
                    <br />
                    <div>
                        <table id="hours" style={{}}>
                            <tr style={{ border: '1px solid black', height: '30px' }}>
                                <td style={{ width: '200px' }}><b>Twin Oaks Condos: </b></td>
                                <td>{values.toCondosHours}</td>
                            </tr>
                            <tr style={{ border: '1px solid black', backgroundColor: '#f2f2f2', height: '30px' }}>
                                <td><b>Twin Creek Condos: </b></td>
                                <td>{values.tcCondosHours}</td>
                            </tr>
                            <tr style={{ border: '1px solid black', height: '30px' }}>
                                <td><b>Twin Oaks HOA: </b></td>
                                <td>{values.toHoaHours}</td>
                            </tr>
                            <tr style={{ border: '1px solid black', backgroundColor: '#f2f2f2', height: '30px' }}>
                                <td><b>Twin Creek HOA: </b></td>
                                <td>{values.tcHoaHours}</td>
                            </tr>
                            <tr style={{ border: '1px solid black', backgroundColor: '#c1c9c6', height: '30px' }}>
                                <td><b>Total Hours: </b></td>
                                <td><b>{values.totalHours}</b></td>
                            </tr>
                        </table>
                    </div>

                </Box>

                <br /><br />

                <div style={{ float: 'right', marginRight: '20px' }}>
                    <Controls.Button
                        type="submit"
                        text='Submit for Approval'
                    />
                    <Controls.Button
                        text="Cancel"
                        color="default"
                        onClick={() => { props.setOpenPopup(false) }}
                    />
                </div>
            </Form>
        )
    } else if (props.popFunction === 'editAndSubmitTime') {

        let taskMap = props.taskList.reduce(function (acc, cur, i) {
            acc[cur.id] = cur.name;

            return acc;
        }, {});

        const updateTotalHours = (oldRowHours, newRowHours) => {

            if(!newRowHours)
                newRowHours = "0.0";

            let total = parseFloat(totalHours);
            total -= parseFloat(oldRowHours);
            total += parseFloat(newRowHours);

            setTotalHours(total.toFixed(1));

        }

        const columns = [
            {
                title: 'Organization',
                field: 'orgId',
                cellStyle: { fontWeight: '700' },
                lookup: { 1: 'TC Condos', 2: 'TO Condos', 3: 'TC HOA', 4: 'TO HOA' },
                // validate: rowData => {
                //     if(rowData.orgId === undefined || rowData.orgId === '')
                //         return '*Required' 
                //     else 
                //         return true 
                // }
            },
            {
                title: 'Task',
                field: 'taskId',
                lookup: taskMap,
                // validate: rowData => {
                //     if(rowData.taskId === undefined || rowData.taskId === '')
                //         return '*Required' 
                //     else 
                //         return true  
                // }
            },
            {
                title: 'Unit#',
                field: 'unitNumber',
                cellStyle: { width: '100px' },
                // validate: rowData => {
                //     if(rowData.taskId === undefined || rowData.taskId === '')
                //         return '*Required' 
                //     else 
                //         return true  
                // }
            },
            {
                title: 'Workorder',
                field: 'workorder',
                cellStyle: { width: '150px' },
                // validate: rowData => {
                //     if(rowData.taskId === undefined || rowData.taskId === '')
                //         return '*Required' 
                //     else 
                //         return true  
                // }
            },
            {
                title: 'Hours',
                field: 'hours',
                type: 'numeric',
                // validate: rowData => {
                //     if(rowData.hours === undefined || rowData.hours === '')
                //         return '*Required' 
                //     else if(parseFloat(rowData.hours) <= 0 || parseFloat(rowData.hours) > 18)
                //         return "Invalid Hours"
                //     else 
                //         return true 
                // }
            },
            {
                title: 'Notes',
                field: 'notes',
                cellStyle: { width: '350px' },
                // validate: rowData => {
                //     if(rowData.notes === undefined || rowData.notes.trim() === '')
                //         return '*Required'
                //     else if(rowData.notes.trim().length < 6)
                //         return 'Enter at least 6 characters'
                //     else 
                //         return true
                // }
            }
        ];

        return (

            <div >
                <div>
                    <MaterialTable
                        icons={tableIcons}
                        title={values.employeeFullName + ' - ' + getParsedDate(values.startDate) +
                            ' (' + getDayOfMonth(values.startDate) + ') Total: ' + totalHours + ' hours'}
                        data={orgTaskHours}
                        columns={columns}
                        options={{
                            sorting: true,
                            actionsColumnIndex: 6,
                            headerStyle: { height: 12, maxHeight: 12, padding: 2, fontSize: '14px', fontWeight: '700' },
                            cellStyle: { height: 12, maxHeight: 12, verticalAlign: 'center', padding: 1 },
                            rowStyle: { height: 12, maxHeight: 12, fontSize: '12px' }
                        }}
                        editable={{
                            onRowUpdateCancelled: rowData => console.log('Row editing cancelled'),
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    newData.timeEntryId = props.recordObject.id;
                                    setTimeout(() => {                                        
                                        setOrgTaskHours([...orgTaskHours, newData]);
                                        updateTotalHours(0, newData.hours);
                                        resolve();
                                    }, 2000)
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    const dataUpdate = [...orgTaskHours];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = newData;
                                    setTimeout(() => {
                                        setOrgTaskHours([...dataUpdate]);
                                        updateTotalHours(oldData.hours, newData.hours);
                                        resolve();
                                    }, 2000)
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    const dataDelete = [...orgTaskHours];
                                    const index = oldData.tableData.id;
                                    dataDelete.splice(index, 1);
                                    setTimeout(() => {
                                        setOrgTaskHours([...dataDelete]);
                                        updateTotalHours(oldData.hours, 0);
                                        resolve()
                                    }, 2000)
                                }),
                        }}
                    />

                </div>
                <div style={{ float: 'right', marginRight: '20px' }}>
                    <Controls.Button
                        text="Cancel"
                        color="default"
                        onClick={() => { props.setOpenPopup(false) }}
                        style={{ height: '35px' }}
                    />
                    &nbsp;&nbsp;
                    <Controls.Button
                        text="Save & Close"
                        class="btn btn-success"
                        onClick={() => { saveTimeEntry('save') }}
                        style={{ height: '35px' }}
                    />
                    &nbsp;&nbsp;
                    <Controls.Button
                        text='Submit for Approval'
                        onClick={() => { saveTimeEntry('saveAndSubmit') }}
                        style={{ height: '35px' }}
                    />
                </div>

            </div>
        )
    } else if (props.popFunction === 'addTimeEntry') {

        return (
            <Form onSubmit={handleSubmit}>
                <br />
                <Grid container>
                    <Grid item xs={8} style={{ width: '680px' }}>

                        <Controls.Select
                            name="employeeList"
                            label="--------------- Select an Employee -----------"
                            onChange={setSelectedEmpId}
                            options={employeeListOptions}
                            error={errors.employee}
                        />

                        <div style={{ display: (timeEntry != null) ? "block" : "none" }}>

                            <b>Next Start Date: </b>{(timeEntry != null) && getParsedDate(timeEntry.startDate)}
                            &nbsp; - &nbsp;
                            <b>End Date:</b> {(timeEntry != null) && getParsedDate(timeEntry.endDate)}

                        </div>

                    </Grid>
                </Grid>

                <br />
                <div style={{ float: 'right', marginRight: '20px' }}>
                    <Controls.Button
                        type="submit"
                        text='Create Time Entry'
                    />
                    <Controls.Button
                        text="Cancel"
                        color="default"
                        onClick={() => { props.setOpenPopup(false) }}
                        style={{ display: (props.popFunction === 'view') ? 'none' : '' }}
                    />

                </div>
            </Form>
        )
    } else if (props.popFunction === 'viewTimeEntry') {

        if (props.recordObject.type === 'Daily') {

            let taskMap = props.taskList.reduce(function (acc, cur, i) {
                acc[cur.id] = cur.name;

                return acc;
            }, {});

            const columns = [
                {
                    title: 'Organization',
                    field: 'orgId',
                    cellStyle: { fontWeight: '700' },
                    lookup: { 1: 'TC Condos', 2: 'TO Condos', 3: 'TC HOA', 4: 'TO HOA' }
                },
                {
                    title: 'Task',
                    field: 'taskId',
                    lookup: taskMap
                },
                {
                    title: 'Unit#',
                    field: 'unitNumber',
                    cellStyle: { align: 'right' } 
                },
                {
                    title: 'Workorder',
                    field: 'workorder',
                },
                {
                    title: 'Hours',
                    field: 'hours',
                    type: 'numeric'
                },
                {
                    title: 'Notes',
                    field: 'notes',
                    cellStyle: { width: '350px' }
                }
            ];

            return (
                <div style={{ width: '700px' }}>
                    <div>
                        <MaterialTable
                            icons={tableIcons}
                            title={values.employeeFullName + ' - ' + getParsedDate(values.startDate) +
                                ' (' + getDayOfMonth(values.startDate) + ') Total: ' + totalHours + ' hours'}
                            data={orgTaskHours}
                            columns={columns}
                            options={{
                                sorting: true,
                                search: false,
                                filtering: false,
                                isLoading: true,
                                actionsColumnIndex: 4,
                                headerStyle: { height: 12, maxHeight: 14, padding: 2, fontSize: '14px', fontWeight: '700' },
                                cellStyle: { height: 12, maxHeight: 14, verticalAlign: 'center', padding: 1 },
                                rowStyle: { height: 12, maxHeight: 14, fontSize: '14px' }
                            }}
                        />

                    </div>
                     &nbsp;&nbsp;
                    <div style={{ float: 'right', marginRight: '20px' }}>
                   
                        <Controls.Button
                            text='Approve'
                            class="btn btn-success"
                            onClick={() => { submitForm(props.recordObject, 'approveTimeEntry') }}
                            style={{ height: '35px', display: (props.recordObject.status === 'Submitted' && userData.adminUser) ? '' : 'none'}}
                        />
                         &nbsp;&nbsp;
                        <Controls.Button
                            text='Reject Time'
                            color="secondary"
                            onClick={() => { submitForm(props.recordObject, 'rejectTimeEntry') }}
                            style={{ height: '35px', display: (props.recordObject.status === 'Submitted' && userData.adminUser) ? '' : 'none'}}
                        />
                         &nbsp;&nbsp;
                        <Controls.Button
                            text="Close"
                            onClick={() => { props.setOpenPopup(false) }}
                            style={{ height: '35px' }}
                        />
                        
                    </div>
                </div>
            )
        } else {

            return (
                <Form>
                    <Box style={{ width: '350px' }}>
                        <br />
                        <div style={{ fontSize: '18px' }}>
                            {values.employeeFullName}
                            &nbsp;
                            ({getParsedDate(values.startDate)}
                            &nbsp;
                            to
                            &nbsp;
                            {getParsedDate(values.endDate)}
                            )
                        </div>
                        <br />
                        <div>
                            <table id="hours" style={{}}>
                                <tr style={{ border: '1px solid black', height: '30px' }}>
                                    <td style={{ width: '200px' }}><b>Twin Oaks Condos: </b></td>
                                    <td>{values.toCondosHours}</td>
                                </tr>
                                <tr style={{ border: '1px solid black', backgroundColor: '#f2f2f2', height: '30px' }}>
                                    <td><b>Twin Creek Condos: </b></td>
                                    <td>{values.tcCondosHours}</td>
                                </tr>
                                <tr style={{ border: '1px solid black', height: '30px' }}>
                                    <td><b>Twin Oaks HOA: </b></td>
                                    <td>{values.toHoaHours}</td>
                                </tr>
                                <tr style={{ border: '1px solid black', backgroundColor: '#f2f2f2', height: '30px' }}>
                                    <td><b>Twin Creek HOA: </b></td>
                                    <td>{values.tcHoaHours}</td>
                                </tr>
                                <tr style={{ border: '1px solid black', backgroundColor: '#c1c9c6', height: '30px' }}>
                                    <td><b>Total Hours: </b></td>
                                    <td><b>{values.totalHours}</b></td>
                                </tr>
                            </table>
                        </div>

                    </Box>

                    <br /><br />

                    <div style={{ float: 'center', marginRight: '20px' }}>
                        <Controls.Button
                            text='Approve'
                            class="btn btn-success"
                            onClick={() => { submitForm(props.recordObject, 'approveTimeEntry') }}
                            style={{ height: '35px', display: (props.recordObject.status === 'Submitted' && userData.adminUser) ? '' : 'none'}}
                        />
                         &nbsp;&nbsp;
                        <Controls.Button
                            text='Reject Time'
                            color="secondary"
                            onClick={() => { submitForm(props.recordObject, 'rejectTimeEntry') }}
                            style={{ height: '35px', display: (props.recordObject.status === 'Submitted' && userData.adminUser) ? '' : 'none'}}
                        />
                         &nbsp;&nbsp;
                        <Controls.Button
                            text="Close"
                            onClick={() => { props.setOpenPopup(false) }}
                        />
                    </div>
                </Form>
            )
        }
    }
}
