import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { userLogin } from '../services/authenticationService';
import './../loginpage.css';
import {setUserData} from '../constants/global'

class LoginPage extends Component {

    constructor(props) {
        super(props)

        localStorage.clear();

        this.state = {

            authInfo: {
                userName: '',
                password: ''
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeUserNameHandler = this.changeUserNameHandler.bind(this);
        this.changePasswordHandler = this.changePasswordHandler.bind(this);
    }

    handleSubmit(e) {

        e.preventDefault();

        userLogin(this.state.authInfo).then((response) => {

            console.log("token::::::::: " + response.data.token);
            console.log("response.status::::::::: " + response.status);
            if (response.status === 200) {
                localStorage.setItem('USER_KEY', response.data.token);
                setUserData(response.data.userFirstName, response.data.userLastName, response.data.userRoles);
                this.props.history.push('/home');
            }
            else {
                console.log("login failure. Something wrong! response.status is: " + response.status);
                //this.props.loginFailure('Something Wrong!Please Try Again'); 
            }
        }).catch((err) => {

            console.log("login failed. Something wrong! error is: " + err);

            if (err && err.response) {

                switch (err.response.status) {
                    case 401:
                        console.log("401 status");
                        this.props.loginFailure("Authentication Failed.Bad Credentials");
                        break;
                    default:
                        this.props.loginFailure('Something Wrong!Please Try Again');

                }

            }
            else {
                this.props.loginFailure('Something Wrong!Please Try Again');
            }
        })
    }

    changeUserNameHandler = (event) => {
        this.setState({ authInfo: { userName: event.target.value, password: this.state.authInfo.passwordpassword } });
    }

    changePasswordHandler = (event) => {
        this.setState({ authInfo: { userName: this.state.authInfo.userName, password: event.target.value } });
    }

    render() {
        return (
            <div className="login-page">

                <section className="h-100">
                    <div className="container h-100">

                        <div className="row justify-content-md-center h-100">
                            <div className="card-wrapper">

                                <div className="card fat">
                                    <div className="card-body">
                                        <h4 className="card-title">Twins HOA Management</h4>

                                        <form className="my-login-validation" onSubmit={this.handleSubmit} noValidate={false}>
                                            <div className="form-group">
                                                <label htmlFor="email">User Name</label>
                                                <input id="username" type="text" autoFocus className="form-control" minLength={5}
                                                    defaultValue={this.state.authInfo.userName} onChange={this.changeUserNameHandler} name="userName" required />

                                                <div className="invalid-feedback">
                                                    UserId is invalid
                                                </div>
                                            </div>

                                            <div className="form-group">

                                                <label>Password &nbsp;
                                                    {/* <a href="forgot.html" className="float-right">
                                                        Forgot Password?
                                                    </a> */}
                                                </label>
                                                
                                                <input id="password" type="password" className="form-control" minLength={8}
                                                    defaultValue={this.state.authInfo.password} onChange={this.changePasswordHandler} name="password" required />
                                                <div className="invalid-feedback">
                                                    Password is required
                                                </div>
                                            </div>

                                            {/* <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                    <span className="custom-control-label"> Remember me</span>
                                                </div>
                                            </div> */}

                                            <br />

                                            <div className="form-group m-0">
                                                <button type="submit" className="btn btn-primary">
                                                    Login
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default withRouter(LoginPage)