import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, remainingTime }) => {

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>You've been idle for a while!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                The current session will expire in{" "} 
                    <span style={{color: 'red', fontWeight: 700}}>{remainingTime}</span> seconds.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleLogout}>
                    Logout
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Stay
                </Button>
            </Modal.Footer>
        </Modal>
    )
}