import axios from 'axios';

import { API_BASE_URL } from '../constants/global';

const TASK_API_BASE_URL = API_BASE_URL + "/tasks"; 

class TaskService {

    getTasks(){
        //console.log("getTasks()::::: " + localStorage.getItem('USER_KEY'));
        return axios({
            method:'GET',
            url: TASK_API_BASE_URL,
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    createTask(task){
        return axios.post(TASK_API_BASE_URL, task, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
          })
    }

    getTaskById(taskId){
        return axios({
            method:'GET',
            url: TASK_API_BASE_URL + '/' + taskId,
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    updateTask(task, taskId){
        console.log("In updateTask()::::::::::::::::;" + taskId);
        return axios.put(TASK_API_BASE_URL + '/' + taskId, task, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
          })
    }

    deleteTask(taskId){
        return axios.delete(TASK_API_BASE_URL + '/' + taskId);
    }
}

export default new TaskService()