const API_BASE_URL = "https://devapi.twinshoa.com/api/v1";
//const API_BASE_URL = "http://localhost:8080/api/v1";
//const API_BASE_URL = "http://ec2-18-188-230-59.us-east-2.compute.amazonaws.com:8080:8080/api/v1";
const userData = {
                    firstName: "",
                    lastName: "",
                    userRoles: [],
                    adminUser: false
                };

function setUserData(firstName, lastName, userRoles) {
    userData.firstName = firstName;
    userData.lastName = lastName;
    userData.userRoles = userRoles;
    setAdminUser();

    console.log('userData.userRoles: ' + userData.userRoles);
}

function setAdminUser() {
    if(userData.userRoles.includes('ADMIN'))
        userData.adminUser =  true;
    else
        userData.adminUser =  false;
}

export {API_BASE_URL, setUserData, userData}