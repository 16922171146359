import React, { useState, useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';


const genderItems = [
    { id: 'male', title: 'Male' },
    { id: 'female', title: 'Female' },
    { id: 'other', title: 'Other' },
] 

const initialFValues = {
    id: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    emailId: '',
    mobilePhone: '',
    homePhone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    gender: '',
    status: '',
    joinedDate: null,
    terminatedDate: null,
    permanent: false,
    tempAgency: '',
    salary: '0.0',
    salaryType: 'hourly',
}

export default function EmployeeForm(props) {
    const { addOrEdit, popFunction, recordForEdit, setOpenPopup } = props

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstName' in fieldValues)
            temp.firstName = fieldValues.firstName ? "" : "This field is required."
        if ('emailid' in fieldValues)
            temp.emailid = (/$^|.+@.+..+/).test(fieldValues.emailid) ? "" : "Email is not valid."
        if ('mobilephone' in fieldValues)
            temp.mobilephone = fieldValues.mobilephone.length > 9 ? "" : "Minimum 10 numbers required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6} style={{width:'480px'}}>
                    <Controls.Input
                        name="firstName"
                        label="First Name"
                        value={values.firstName}
                        onChange={handleInputChange}
                        error={errors.firstName}
                        style={{width:"100px"}}
                    />
                    <Controls.Input
                        name="middleName"
                        label="Middle"
                        value={values.middleName}
                        onChange={handleInputChange}
                        error={errors.middleName}
                        style={{width:"100px"}}
                    />
                    <Controls.Input
                        name="lastName"
                        label="Last Name"
                        value={values.lastName}
                        onChange={handleInputChange}
                        error={errors.lastName}
                        style={{width:"120px"}}
                    />
                    <Controls.Input
                        label="Email"
                        name="emailId"
                        value={values.emailId}
                        onChange={handleInputChange}
                        error={errors.emailId}                        
                        style={{width:"355px"}}
                    />
                    <Controls.Input
                        label="Mobile Phone"
                        name="mobilePhone"
                        value={values.mobilePhone}
                        onChange={handleInputChange}
                        error={errors.mobilePhone}
                        style={{width:"170px"}}
                    />
                    <Controls.Input
                        label="Home Phone"
                        name="homePhone"
                        value={values.homePhone}
                        onChange={handleInputChange}
                        error={errors.homePhone}
                        style={{width:"170px"}}
                    />
                    <Controls.Input
                        label="Address 1"
                        name="address1"
                        value={values.address1}
                        onChange={handleInputChange}
                        style={{width:"170px"}}
                    />
                    <Controls.Input
                        label="Address 2"
                        name="address2"
                        value={values.address2}
                        onChange={handleInputChange}
                        style={{width:"170px"}}
                    />
                    <Controls.Input
                        label="City"
                        name="city"
                        value={values.city}
                        onChange={handleInputChange}
                        style={{width:"140px"}}
                    />
                   <Controls.Input
                        name="state"
                        label="State"
                        value={values.state}
                        onChange={handleInputChange}
                        error={errors.state}
                        style={{width:"80px"}}
                    />
                    <Controls.Input
                        name="zipcode"
                        label="Zipcode"
                        value={values.zipcode}
                        onChange={handleInputChange}
                        style={{width:"105px"}}
                    />

                </Grid>
                <Grid item xs={6}> 
                    <Controls.RadioGroup
                        name="gender"
                        label="Gender"
                        value={values.gender}
                        onChange={handleInputChange}
                        items={genderItems}
                    />
                    <div style={{ width: '220px', display: 'inline-block'}}>
                        <Controls.DatePicker
                            name="joinedDate"
                            label="Joined On"
                            value={values.joinedDate}
                            onChange={handleInputChange} 
                        />
                    </div>
                    <div style={{ width: '220px', display: 'inline-block'}}>
                        <Controls.DatePicker
                            name="terminatedDate"
                            label="Terminated On"
                            value={values.terminatedDate}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div style={{ width: '140px', display: 'inline-block'}}>
                        <Controls.Checkbox
                            name="permanent"
                            label="Permanent"
                            value={values.permanent}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div style={{ width: '300px', display: 'inline-block'}}>
                        <Controls.Input
                            label="Temp Agency"
                            name="tempAgency"
                            value={values.tempAgency}
                            onChange={handleInputChange}
                            style={{display: (values.permanent == true) ? 'none':''}}
                        />
                    </div>
                    <div style={{ width: '150px', display: 'inline-block'}}>
                        <Controls.Input
                            label="Salary ($)"
                            name="salary"
                            value={values.salary}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div style={{ width: '180px', display: 'inline-block'}}>
                        <Controls.Select
                            name="salaryType"
                            label="Salary Type"
                            value={values.salaryType}
                            onChange={handleInputChange}
                            options={[
                                { id: 'hourly', title: 'Per Hour' },
                                { id: 'weekly', title: 'Per Week' },
                                { id: 'monthly', title: 'Per Month' },
                                { id: 'annually', title: 'Per Annum' }
                            ]}
                        />
                    </div>
                </Grid>
            </Grid>
            
            <div style={{float:'right', marginRight: '20px'}}>
                <Controls.Button
                    type="submit"
                    text={(props.popFunction == 'update') ? 'Update' : 'Submit'}
                    style={{display: (props.popFunction == 'view') ? 'none':''}}
                     />
                <Controls.Button
                    text="Cancel"
                    color="default"
                    onClick={()=>{props.setOpenPopup(false)}}
                    style={{display: (props.popFunction == 'view') ? 'none':''}}
                     />
            </div>
        </Form>
    )
}
