import React from 'react';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import PropTypes from 'prop-types';
import SecuredLayout from './components/SecuredLayout';

class App extends React.Component {

  render() {

    return (

      <div>
        <BrowserRouter>
          <div className="container">
            <Switch>
              <Route exact path="/login" component={LoginPage}></Route>
              <Route path='/' render={(props) => <SecuredLayout {...props} />} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>

    );
  }
}

App.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired
}

export default withRouter(App)
