import axios from 'axios';
import { API_BASE_URL } from '../constants/global';

const TIME_ENTRY_API_BASE_URL = API_BASE_URL + "/time_entries";

class TimeEntrieservice {

    getTimeEntries(){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("getTimeEntries() userToken::::: " + userToken);
        
        return axios({
            method:'GET',
            url: TIME_ENTRY_API_BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + userToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    getNextPayPeriod(employeeId){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("getNexPayPeriod() userToken::::: " + userToken);
        
        return axios({
            method:'GET',
            url: TIME_ENTRY_API_BASE_URL + "/nextPayPeriod?employeeId=" + employeeId,
            headers: {
                'Authorization': 'Bearer ' + userToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    createTimeEntry(timeEntry){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("employeeId::::: " + timeEntry.employeeId);
       
        return axios.post(TIME_ENTRY_API_BASE_URL, timeEntry, {
            headers:{
                'Authorization': 'Bearer ' + userToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    saveDailyTaskHours(timeEntries, timeEntryId, action){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("saveDailyTaskHours() userToken::::: " + userToken);
        
        return axios.put(TIME_ENTRY_API_BASE_URL + '/' + timeEntryId + '/dailyTasks?action=' + action, timeEntries, {
            headers:{
                'Authorization': 'Bearer ' + userToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    getDailyTasksByTimeEntryId(timeEntryId){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("getDailyTasksByTimeEntryId():::::after " + userToken);

        return axios({
            method:'GET',
            url: TIME_ENTRY_API_BASE_URL + '/' + timeEntryId + '/dailyTasks',
            headers:{
                'Authorization': 'Bearer ' +  userToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    gettimeEntryById(timeEntryId){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("gettimeEntryById() userToken::::: " + userToken);
        
        return axios({
            method:'GET',
            url: TIME_ENTRY_API_BASE_URL + '/' + timeEntryId,
            headers:{
                'Authorization': 'Bearer ' +  userToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    changeTimeEntryStatus(timeEntryId, action){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("updatetimeEntry() userToken::::: " + userToken);
        
        return axios.put(TIME_ENTRY_API_BASE_URL + "/" + timeEntryId + '/' + action , null, {
            headers:{
                'Authorization': 'Bearer ' +  userToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
          }).catch(err => {
              throw err;
          });
    }

    updatetimeEntry(timeEntry, timeEntryId){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("updatetimeEntry() userToken::::: " + userToken);
        
        return axios.put(TIME_ENTRY_API_BASE_URL + '/' + timeEntryId, timeEntry, {
            headers:{
                'Authorization': 'Bearer ' +  userToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
          })
    }

    deletetimeEntry(timeEntryId){
        let userToken = localStorage.getItem('USER_KEY');
        console.log("deletetimeEntry() userToken::::: " + userToken);
        
        return axios.delete(TIME_ENTRY_API_BASE_URL + '/' + timeEntryId);
    } 
}

export default new TimeEntrieservice()