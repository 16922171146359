import React, { Component } from 'react'
import TaskService from '../services/TaskService';
import MaterialTable from "material-table";
import tableIcons from "./MaterialTableIcons";

import TaskForm from "../pages/forms/TaskForm"
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment } from '@material-ui/core';
import Controls from "./controls/Controls";
import AddIcon from '@material-ui/icons/Add';
import Popup from "./Popup";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";

// MaterialTable documentation - https://material-table.com/#/docs/get-started

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '75%'
    },
    newButton: {
        position: 'absolute',
        right: '10px'
    }
}))

class ListTaskComponent extends Component {

    constructor(props) {
        super(props)

        this.setOpenPopup = this.setOpenPopup.bind(this);
        this.setPopFunction = this.setPopFunction.bind(this);
        this.addOrEdit = this.addOrEdit.bind(this);
        this.setNotify = this.setNotify.bind(this);

        this.state = {
            tasks: [],
            openPopup: false,
            popupAction: "view",
            popupTitle: "View Task",
            notify: { isOpen: false, message: '', type: '' },
            confirmDialog: { isOpen: false, title: '', subTitle: '' },
            recordForEdit: null
        };

    }

    setOpenPopup(obj) {
        this.setState({ openPopup: obj});
    }

    onDelete(id) {
        this.setState({
            ...this.state.confirmDialog,
            isOpen: false
        })

        this.deleteTask(id);
        this.getAllTasks();
        this.setNotify({
            isOpen: true,
            message: 'Deleted Successfully',
            type: 'error'
        })
    }

    deleteTask(id) {

        TaskService.deleteTask(id).then( res => {
            this.setState({tasks: this.state.tasks.filter(task => task.id !== id)});
        });
    }

    viewTask(id){
        console.log("In viewTask():::::::::::: " + id);
        this.props.history.push(`/view-task/${id}`);
    }

    createTask(task) {
        TaskService.createTask(task).then(res =>{
            this.getAllTasks();
        });
    }

    editTask(task){
        TaskService.updateTask(task, task.id).then(res =>{
            this.getAllTasks();
        });
    }

    getAllTasks() {
        TaskService.getTasks().then((res) => {
            this.setState({ tasks: res.data});
        }).catch((e)=>{
            localStorage.clear();
            this.props.history.push('/login');
        });
    }

    componentDidMount() {
        this.getAllTasks();
    }

    addOrEdit(task, resetForm) {
        console.log("In addOrEdit():::::::::::: " + task.id);
        if (task.id == 0)
            this.createTask(task)
        else
            this.editTask(task)

        //resetForm()
        this.state.recordForEdit = null;
        this.state.notify = false;
        this.state.openPopup = false;
        this.state.notify = {
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
        }
    }

    openInPopup(obj, popFunction, title) {
        this.setRecordForEdit(obj);
        this.setPopFunction(popFunction);
        this.setOpenPopup(true);
        this.setState({popupTitle: title});
    }

    setNotify(obj) {
        this.setState({ notify: obj});
    }

    setConfirmDialog(obj) {
        this.setState({ confirmDialog: obj});
    }

    setRecordForEdit(obj) {
        this.setState({ recordForEdit: obj});
    }

    setPopFunction(obj) {
        this.setState({ popupAction: obj});
    }

    render() {

        const options = {
            filterType: "checkbox",
          };

        const columns = [
                {
                    title: 'Name',
                    field: 'name',
                    defaultSort: 'asc'
                },
                {
                    title: 'Description',
                    field: 'description'
                },
                {
                    title: 'Type',
                    field: 'type'
                }
            ];

        return (

            <div>
                <br></br>
                <Controls.Button
                        text="Add New"
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => {  this.openInPopup(null, 'add', 'Add Task'); }}
                    />
                <div style={{ }}>
                           
                        <MaterialTable
                            icons={tableIcons} 
                            title="Task List"
                            data={this.state.tasks}
                            columns={columns}
                            options={{  sorting: true, 
                                        search: true, 
                                        paging: true, 
                                        filtering: false, 
                                        exportButton: true, 
                                        actionsColumnIndex: 6,
                                        headerStyle:{height:20, maxHeight:20, padding:2, fontSize:'14px', fontWeight:'700'},
                                        cellStyle:{verticalAlign:'center', padding:2},
                                        rowStyle: {height:12, fontSize:'12px'}
                                    }}
                            onRowClick={(evt, selectedRow) => this.openInPopup(selectedRow, 'view', 'View Task')}
                            actions={[
                                {
                                  icon: tableIcons.Edit,
                                  tooltip: 'Update',
                                  onClick: (event, selectedRow) => {
                                    this.openInPopup(selectedRow, "update", 'Edit Task');
                                  }
                                },
                                {
                                    icon: tableIcons.Delete,
                                    tooltip: 'Delete',
                                    onClick: (event, selectedRow) => {
                                      this.onDelete(selectedRow.id);
                                    }
                                  }
                              ]}
                        /> 
                </div>

                <Popup
                    title={this.state.popupTitle}
                    openPopup={this.state.openPopup}
                    setOpenPopup={this.setOpenPopup}
                >                    
                    <TaskForm
                        popFunction={this.state.popupAction}
                        recordForEdit={this.state.recordForEdit}
                        addOrEdit={this.addOrEdit}
                        setOpenPopup={this.setOpenPopup}
                        popupAction={this.state.popupAction} />
                </Popup>

                <Notification
                    notify={this.state.notify}
                    setNotify={this.setNotify}
                />

                <ConfirmDialog
                    confirmDialog={this.state.confirmDialog}
                    setConfirmDialog={this.setConfirmDialog}
                />
            </div>
        )
    }
}

export default ListTaskComponent
