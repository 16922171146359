import React from 'react'
import { InputLabel } from '@material-ui/core';

export default function Label(props) {

    const { children, ...other } = props;
    return (
        <InputLabel
            variant="standard"
            {...other}
        >{children}</InputLabel>
    )
}
