import axios from 'axios';
import { API_BASE_URL } from '../constants/global';

const AUTH_API_BASE_URL = API_BASE_URL + "/auth/login";

export const userLogin=(authRequest)=>{
    //console.log("AUTH_API_BASE_URL::::::::::::::: " + AUTH_API_BASE_URL);
    return axios({
        'method':'POST',
        'url': AUTH_API_BASE_URL,
        'data': authRequest
    }) 
}

export const fetchUserData=(authRequest)=>{
    return axios({
        method:'GET',
        url: API_BASE_URL + `/auth/userinfo`,
        headers:{
            'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*" 
          }
    })
}