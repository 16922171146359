import axios from 'axios';
import { API_BASE_URL } from '../constants/global';

const EMPLOYEE_API_BASE_URL = API_BASE_URL + "/employees";

class EmployeeService {

    getEmployees(status){
        console.log("getEmployees()::::: " + localStorage.getItem('USER_KEY'));

        let endpoint = EMPLOYEE_API_BASE_URL;
        if(status != null)
            endpoint += "?status=" + status;

        console.log("endpoint = " + endpoint);

        return axios({
            method:'GET',
            url: endpoint,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    createEmployee(employee){
        return axios.post(EMPLOYEE_API_BASE_URL, employee, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
          })
    }

    getEmployeeById(employeeId){
        return axios({
            method:'GET',
            url: EMPLOYEE_API_BASE_URL + '/' + employeeId,
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
        })
    }

    updateEmployee(employee, employeeId){
        console.log("In updateTask()::::::::::::::::;" + employeeId);
        return axios.put(EMPLOYEE_API_BASE_URL + '/' + employeeId, employee, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*" 
              }
          })
    }

    deleteEmployee(employeeId){
        return axios.delete(EMPLOYEE_API_BASE_URL + '/' + employeeId);
    }
}

export default new EmployeeService()