import React, { Component } from 'react'
import TimeEntryService from '../services/TimeEntryService';
import TaskService from '../services/TaskService';
import MaterialTable from "material-table";
import tableIcons from "./MaterialTableIcons";

import TimeEntryForm from "../pages/forms/TimeEntryForm"
import Controls from "./controls/Controls";
import AddIcon from '@material-ui/icons/Add';
import Popup from "./Popup";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";
import { API_BASE_URL } from '../constants/global';

// MaterialTable documentation - https://material-table.com/#/docs/get-started

class ListRemoteDataComponent extends Component {

    constructor(props) {
        super(props)

        this.setOpenPopup = this.setOpenPopup.bind(this);
        this.setPopFunction = this.setPopFunction.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.setNotify = this.setNotify.bind(this);

        this.state = {
            timeEntries: [],
            taskList: [],
            openPopup: false,
            popupAction: "view",
            popupTitle: "View Time Entry",
            notify: { isOpen: false, message: '', type: '' },
            confirmDialog: { isOpen: false, title: '', subTitle: '' },
            recordObject: null,
            selectedRowIndex: -1,
            selectedRowId: -1
        };
    }

    setOpenPopup(obj) {
        this.setState({ openPopup: obj });
    }

    onDelete(id) {
        this.setState({
            ...this.state.confirmDialog,
            isOpen: false
        })

        this.deleteTimeEntry(id);
        this.getAllTimeEntries();
        this.setNotify({
            isOpen: true,
            message: 'Deleted Successfully',
            type: 'error'
        })
    }

    getTimeEntry(id) {

        TimeEntryService.gettimeEntryById(id).then(res => {
            this.setState({ recordObject: res.data });
        });
    }

    deleteTimeEntry(id) {

        TimeEntryService.deleteTimeEntry(id).then(res => {
            this.setState({ timeEntries: this.state.timeEntries.filter(timeEntry => timeEntry.id !== id) });
        });
    }

    viewTimeEntry(id) {
        console.log("In viewTimeEntry():::::::::::: " + id);
        //this.props.history.push(`/view-timeEntry/${id}`);
    }

    getParsedDate(strDate) {
        if (strDate != null && strDate === "")
            return "";

        var strSplitDate = String(strDate).split(' ');
        var date = new Date(strSplitDate[0]);
        // alert(date);
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!

        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date = mm + "-" + dd + "-" + yyyy;
        return date.toString();
    }

    // return Date object
    getParsedDateObj(strDate) {
        if (!strDate != null && strDate === "")
            return "";

        var strSplitDate = String(strDate).split(' ');
        var date = new Date(strSplitDate[0]);

        return date;
    }

    createTimeEntry(timeEntry) {
        TimeEntryService.createTimeEntry(timeEntry).then(res => {
            this.getAllTimeEntries();
        });
    }

    submitForApproval(timeEntry) {
        timeEntry.status = 'Submitted';
        TimeEntryService.updatetimeEntry(timeEntry, timeEntry.id).then(res => {
            //this.getAllTimeEntries();

            // Update record
            let items = [...this.state.timeEntries];
            items[this.state.selectedRowIndex] = res.data;
            this.setState({ timeEntries: items });

        });
    }

    saveTimeEntry(timeEntry) {

        let items = [...this.state.timeEntries];
        items[this.state.selectedRowIndex] = timeEntry;

        // Update UI parent state
        if (timeEntry.type === 'Daily') {
            let parentObj = items.find(row => row.id === timeEntry.parentId);
            //let parentObjIdx = items.indexOf(parentObj);

            TimeEntryService.gettimeEntryById(timeEntry.parentId).then(res => {
                //items[parentObjIdx] = res.data;
                const tempObj = res.data;
                parentObj.tcCondosHours = tempObj.tcCondosHours;
                parentObj.toCondosHours = tempObj.toCondosHours;
                parentObj.tcHoaHours = tempObj.tcHoaHours;
                parentObj.toHoaHours = tempObj.toHoaHours;
                parentObj.totalHours = tempObj.totalHours;
                parentObj.status = tempObj.status;
            });
        }

        this.setState({ timeEntries: items });
    }

    editTimeEntry(timeEntry) {
        TimeEntryService.updatetimeEntry(timeEntry, timeEntry.id).then(res => {
            this.getAllTimeEntries();
        });
    }

    getAllTimeEntries() {
        TimeEntryService.getTimeEntries().then((res) => {
            this.setState({ timeEntries: res.data });
        }).catch((e) => {
            console.log("Error occurred: " + e);
        });
    }

    getAllTasks() {
        TaskService.getTasks().then((res) => {
            this.setState({ taskList: res.data });
            console.log('taskList:::::::::::: ' + this.state.taskList);
        }).catch((e) => {
            console.log('error getting task list - ' + e);
        });
    }

    componentDidMount() {
        //this.getAllTimeEntries();
        this.getAllTasks();
    }

    submitForm(timeEntry, resetForm) {
        console.log("In submitForm():::::::::::: " + timeEntry.id);
        var msg = "Problem with the submission"
        if (this.state.popupAction === 'addTimeEntry') {
            this.createTimeEntry(timeEntry);
            msg = "New Time Entry added!!!";
        } else if (this.state.popupAction === 'submitForApproval') {
            this.submitForApproval(timeEntry);
            msg = "Time Entry Submitted for Approval!!!";
        } else if (this.state.popupAction === 'editAndSubmitTime') {
            this.saveTimeEntry(timeEntry);
            msg = "Time Entry save successfully!!!";
        }

        //resetForm() saveTimeEntry
        this.setState({ recordObject: null });
        this.setState({ openPopup: false });
        this.setNotify({ isOpen: true, message: msg, type: 'success' });
    }

    openInPopup(obj, popFunction, title) {

        this.setSelectedRowIndex(this.state.timeEntries.indexOf(obj));

        this.setRecordObject(obj);
        this.setPopFunction(popFunction);
        this.setOpenPopup(true);
        this.setState({ popupTitle: title });
        this.setState({ notify: { isOpen: false, message: '', type: '' } });
    }

    setSelectedRowIndex(rowIndex) {
        this.setState({ selectedRowIndex: rowIndex });
    }

    setNotify(obj) {
        this.setState({ notify: obj });
    }

    setConfirmDialog(obj) {
        this.setState({ confirmDialog: obj });
    }

    setRecordObject(obj) {
        this.setState({ recordObject: obj });
    }

    isWeekend(rowData) {

        if (rowData && rowData.type === 'Daily' && rowData.startDate) {

            let day = this.getParsedDateObj(rowData.startDate).getDay();

            // check for sundays and saturdays
            if (day === 0 || day === 6)
                return true;
        }

        return false;
    }

    setPopFunction(obj) {
        this.setState({ popupAction: obj });
    }

    render() {

        const columns = [
            {
                title: 'Employee',
                field: 'employeeFullName',
                cellStyle: { fontWeight: '700' },
                render: rowData => (rowData.parentId === 0) ? rowData.employeeFullName : ' '
            },
            {
                title: 'Start Date',
                field: 'startDate',
                render: rowData => this.getParsedDate(rowData.startDate)
            },
            {
                title: 'End Date',
                field: 'endDate',
                render: rowData => this.getParsedDate(rowData.endDate)
            },
            {
                title: 'TO Condos',
                field: 'toCondosHours',
                cellStyle: { align: 'center' }
            },
            {
                title: 'TC Condos',
                field: 'tcCondosHours',
                cellStyle: { align: 'center' }
            },
            {
                title: 'TO HOA',
                field: 'toHoaHours',
                cellStyle: { align: 'center' }
            },
            {
                title: 'TC HOA',
                field: 'tcHoaHours',
                cellStyle: { align: 'center' }
            },
            {
                title: 'Total',
                field: 'totalHours',
                cellStyle: { align: 'center', fontWeight: '700' }
            },
            {
                title: 'Status',
                field: 'status'
            }
        ];

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('USER_KEY'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        const data = (query) => (
            new Promise((resolve, reject) => {
                let url = API_BASE_URL + "/time_entries/page?";
                url += "pageSize=" + query.pageSize;
                url += "&page=" + query.page;

                fetch(url, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        resolve({
                            data: result.content,
                            page: result.number,
                            totalCount: result.numberOfElements
                        });
                    });
            }));

        const tableRef = React.createRef();

        return (

            <div>
                <br></br>
                <Controls.Button
                    text="Add New"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => { this.openInPopup(null, 'add', 'Add Time Entry'); }}
                />
                <div>
                    <MaterialTable
                        icons={tableIcons}
                        title="Time Entry"
                        data={data}
                        tableRef={tableRef}
                        columns={columns}
                        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                        options={{
                            sorting: false,
                            search: false,
                            paging: true,
                            filtering: false,
                            exportButton: true,
                            actionsColumnIndex: 9,
                            headerStyle: { height: 16, maxHeight: 16, padding: 5, fontSize: '14px', fontWeight: '700' },
                            cellStyle: { height: 12, verticalAlign: 'center', padding: 1 },
                            rowStyle: rowData => ({
                                fontSize: '12px',
                                backgroundColor: (this.state.selectedRowId === rowData.tableData.id) ? '#EEE' : '#FFF',
                                color: (this.isWeekend(rowData)) ? 'orange' : 'black'
                            })
                        }}
                        onRowClick={(evt, selectedRow) => this.openInPopup(selectedRow, 'view', 'View Time Entry')}
                        actions={[
                            rowData => ({
                                icon: tableIcons.Edit,
                                tooltip: 'Edit & Submit Daily Time',
                                onClick: (event, selectedRow) => {
                                    this.setState({ selectedRowId: selectedRow.tableData.id });
                                    this.openInPopup(selectedRow, "editAndSubmitTime", 'Edit & Submit Daily Time');
                                },
                                hidden: (rowData.type === 'Daily') ? false : true,
                                disabled: rowData.status !== 'Draft'
                            }),
                            rowData => ({
                                icon: tableIcons.Publish,
                                tooltip: 'Submit for Approval',
                                onClick: (event, selectedRow) => {
                                    this.openInPopup(selectedRow, "submitForApproval", 'Submit Time Entry');
                                },
                                hidden: (rowData.type === 'Daily') ? true : false,
                                disabled: rowData.status !== 'Draft'
                            }),
                            rowData => ({
                                icon: tableIcons.Delete,
                                tooltip: 'Delete',
                                onClick: (event, selectedRow) => {
                                    console.lon('Delete this record');
                                },
                                hidden: (rowData.type === 'Daily') ? true : false,
                                disabled: rowData.status !== 'Draft'
                            }),
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            }
                        ]}
                    />
                </div>

                <Popup
                    title={this.state.popupTitle}
                    openPopup={this.state.openPopup}
                    setOpenPopup={this.setOpenPopup}
                >
                    <TimeEntryForm
                        popFunction={this.state.popupAction}
                        recordObject={this.state.recordObject}
                        submitForm={this.submitForm}
                        setOpenPopup={this.setOpenPopup}
                        popupAction={this.state.popupAction}
                        taskList={this.state.taskList} />
                </Popup>

                <Notification
                    notify={this.state.notify}
                    setNotify={this.setNotify}
                />

                <ConfirmDialog
                    confirmDialog={this.state.confirmDialog}
                    setConfirmDialog={this.setConfirmDialog}
                />
            </div>
        )
    }
}

export default ListRemoteDataComponent
