import React, { Component } from 'react'
import { Route , withRouter} from 'react-router-dom';

class HomePage extends Component {

    componentDidMount(){
        
        
    }

    render() {
        return (
            <section style={{width:"100%", marginTop:"80px"}}>
                <center><h1>HOA Management System</h1></center>
            </section>
        )
    }
}

export default withRouter(HomePage)