import React, { useState, useEffect } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';

const initialFValues = {
    id: 0,
    name: '',
    description: '',
    type: ''
}

export default function TaskForm(props) {
    const { addOrEdit, popFunction, recordForEdit, setOpenPopup } = props

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        if ('description' in fieldValues)
            temp.description = fieldValues.description ? "" : "This field is required."
        if ('type' in fieldValues)
            temp.type = fieldValues.type ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6} style={{width:'480px'}}>
                    <Controls.Input
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                    <Controls.Input
                        name="description"
                        label="Description"
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                    <Controls.Input
                        name="type"
                        label="Type"
                        value={values.type}
                        onChange={handleInputChange}
                        error={errors.type}
                    />
                </Grid>
            </Grid>
            
            <div style={{float:'right', marginRight: '20px'}}>
                <Controls.Button
                    type="submit"
                    text={(props.popFunction == 'update') ? 'Update' : 'Submit'}
                    style={{display: (props.popFunction == 'view') ? 'none':''}}
                     />
                <Controls.Button
                    text="Cancel"
                    color="default"
                    onClick={()=>{props.setOpenPopup(false)}}
                    style={{display: (props.popFunction == 'view') ? 'none':''}}
                     />
            </div>
        </Form>
    )
}
