import React, { Component } from 'react'
import EmployeeService from '../services/EmployeeService';
import MaterialTable from "material-table";
import tableIcons from "./MaterialTableIcons";

import EmployeeForm from "../pages/forms/EmployeeForm"
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment } from '@material-ui/core';
import Controls from "./controls/Controls";
import AddIcon from '@material-ui/icons/Add';
import Popup from "./Popup";
import Notification from "./Notification";
import ConfirmDialog from "./ConfirmDialog";

// MaterialTable documentation - https://material-table.com/#/docs/get-started

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '75%'
    },
    newButton: {
        position: 'absolute',
        right: '10px'
    }
}))

class ListEmployeeComponent extends Component {

    constructor(props) {
        super(props)

        this.setOpenPopup = this.setOpenPopup.bind(this);
        this.setPopFunction = this.setPopFunction.bind(this);
        this.addOrEdit = this.addOrEdit.bind(this);
        this.setNotify = this.setNotify.bind(this);

        this.state = {
            employees: [],
            openPopup: false,
            popupAction: "view",
            popupTitle: "View Employee",
            notify: { isOpen: false, message: '', type: '' },
            confirmDialog: { isOpen: false, title: '', subTitle: '' },
            recordForEdit: null
        };

    }

    setOpenPopup(obj) {
        this.setState({ openPopup: obj});
    }

    onDelete(id) {
        this.setState({
            ...this.state.confirmDialog,
            isOpen: false
        })

        this.deleteEmployee(id);
        this.getAllEmployees();
        this.setNotify({
            isOpen: true,
            message: 'Deleted Successfully',
            type: 'error'
        })
    }

    deleteEmployee(id) {

        EmployeeService.deleteEmployee(id).then( res => {
            this.setState({employees: this.state.employees.filter(employee => employee.id !== id)});
        });
    }

    viewEmployee(id){
        console.log("In viewEmployee():::::::::::: " + id);
        this.props.history.push(`/view-employee/${id}`);
    }

    createEmployee(employee) {
        EmployeeService.createEmployee(employee).then(res =>{
            this.getAllEmployees();
        });
    }

    editEmployee(employee){
        EmployeeService.updateEmployee(employee, employee.id).then(res =>{
            this.getAllEmployees();
        });
    }

    getAllEmployees() {
        EmployeeService.getEmployees(null).then((res) => {
            this.setState({ employees: res.data});
        }).catch((e)=>{
            console.log("Error fetching employee list - " + e);
        });
    }

    componentDidMount() {
        this.getAllEmployees();
    }

    addOrEdit(employee, resetForm) {
        console.log("In addOrEdit():::::::::::: " + employee.id);
        if (employee.id == 0)
            this.createEmployee(employee)
        else
            this.editEmployee(employee)

        //resetForm()
        this.state.recordForEdit = null;
        this.state.notify = false;
        this.state.openPopup = false;
        this.state.notify = {
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
        }
    }

    openInPopup(obj, popFunction, title) {
        this.setRecordForEdit(obj);
        this.setPopFunction(popFunction);
        this.setOpenPopup(true);
        this.setState({popupTitle: title});
    }

    setNotify(obj) {
        this.setState({ notify: obj});
    }

    setConfirmDialog(obj) {
        this.setState({ confirmDialog: obj});
    }

    setRecordForEdit(obj) {
        this.setState({ recordForEdit: obj});
    }

    setPopFunction(obj) {
        this.setState({ popupAction: obj});
    }

    render() {

        const options = {
            filterType: "checkbox",
          };

        const columns = [
                {
                    title: 'First Name',
                    field: 'firstName'
                },
                {
                    title: 'Middle Name',
                    field: 'middleName'
                },
                {
                    title: 'Last Name',
                    field: 'lastName'
                },
                {
                    title: 'Status',
                    field: 'status',
                    defaultSort: 'asc'
                },
                {
                    title: 'Mobile',
                    field: 'mobilePhone',
                    sort: 'disabled'
                },
                {
                    title: 'Email',
                    field: 'emailId'
                }
            ];

        return (

            <div>
                <br></br>
                <Controls.Button
                        text="Add New"
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => {  this.openInPopup(null, 'add', 'Add Employee'); }}
                    />
                <div style={{ }}>
                           
                        <MaterialTable
                            icons={tableIcons} 
                            title="Employee List"
                            data={this.state.employees}
                            columns={columns}
                            options={{  sorting: true, 
                                        search: true, 
                                        paging: true, 
                                        filtering: false, 
                                        exportButton: true, 
                                        actionsColumnIndex: 6,
                                        headerStyle:{height:20, maxHeight:20, padding:2, fontSize:'14px', fontWeight:'700'},
                                        cellStyle: {verticalAlign:'center', padding:2},
                                        rowStyle: {height:12, fontSize:'12px'}
                                    }}
                            onRowClick={(evt, selectedRow) => this.openInPopup(selectedRow, 'view', 'View Employee')}
                            actions={[
                                {
                                  icon: tableIcons.Edit,
                                  tooltip: 'Update',
                                  onClick: (event, selectedRow) => {
                                    this.openInPopup(selectedRow, "update", 'Edit Employee');
                                  }
                                },
                                {
                                    icon: tableIcons.Delete,
                                    tooltip: 'Delete',
                                    onClick: (event, selectedRow) => {
                                      this.onDelete(selectedRow.id);
                                    }
                                  }
                              ]}
                        /> 
                </div>

                <Popup
                    title={this.state.popupTitle}
                    openPopup={this.state.openPopup}
                    setOpenPopup={this.setOpenPopup}
                >                    
                    <EmployeeForm
                        popFunction={this.state.popupAction}
                        recordForEdit={this.state.recordForEdit}
                        addOrEdit={this.addOrEdit}
                        setOpenPopup={this.setOpenPopup}
                        popupAction={this.state.popupAction} />
                </Popup>

                <Notification
                    notify={this.state.notify}
                    setNotify={this.setNotify}
                />

                <ConfirmDialog
                    confirmDialog={this.state.confirmDialog}
                    setConfirmDialog={this.setConfirmDialog}
                />
            </div>
        )
    }
}

export default ListEmployeeComponent
