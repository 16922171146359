import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Navbar from './Navbar/Navbar';
import IdleTimer from 'react-idle-timer';
import HomePage from './Home';
import ListEmployeeComponent from './ListEmployeeComponent';
import ListTaskComponent from './ListTaskComponent';
import ListTimeEntryComponent from './ListTimeEntryComponent';
import ListRemoteDataComponent from './ListRemoteDataComponent';
import { IdleTimeOutModal } from '../IdleTimeOutModal'
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'

let countdownInterval;
let timeout;
let countDownTimeout = 1000 * 60 * 15;

class SecuredLayout extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      timeout:countDownTimeout,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      timeoutCountdown: 0
    }

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    //console.log('user is idle', e)
    //const isTimedOut = this.state.isTimedOut;
    const delay = 1000 * 1;
    if (!this.state.showModal) {
      timeout = setTimeout(() => {
        let countDown = 30;
        this.setState({ showModal: true });
        this.idleTimer.reset();
        this.setState({ timeoutCountdown: countDown });
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            this.setState({ timeoutCountdown: --countDown });
          } else {
            console.log("calling handleLogout()");
            this.handleLogout();
          }
        }, 1000);
      }, delay);
    }

  }

  handleClose() {
    this.setState({ showModal: false });
    clearTimeout(timeout);
    clearInterval(countdownInterval);
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.setState({timeout: countDownTimeout})
    clearTimeout(timeout);
    clearInterval(countdownInterval);
    this.props.history.push('/login')
  }

  render() {
    if (localStorage.getItem('USER_KEY') == null) {
      return <Redirect to="/login" />
    }

    const { match } = this.props
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} />

        <div className="">
          <Navbar />
          <Switch>
            <Route
              exact path={`${match.path}`}
              render={(props) => <HomePage {...props} />}
            />
            <Route
              exact path={`${match.path}home`}
              render={(props) => <HomePage {...props} />}
            />
            <Route
              exact path={`${match.path}employees`}
              render={(props) => <ListEmployeeComponent {...props} />}
            />
            <Route
              exact path={`${match.path}tasks`}
              render={(props) => <ListTaskComponent {...props} />}
            />
            <Route
              exact path={`${match.path}time-entries`}
              render={(props) => <ListTimeEntryComponent {...props} />}
            />
            <Route
              exact path={`${match.path}remote-time-entries`}
              render={(props) => <ListRemoteDataComponent {...props} />}
            />
          </Switch>

          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
            remainingTime={this.state.timeoutCountdown}
          />
        </div>
      </>
    )
  }

}

SecuredLayout.propTypes = {
  match: PropTypes.any.isRequired,
  //history: PropTypes.func.isRequired
}

export default SecuredLayout