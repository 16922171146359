import React, { Component } from 'react'
import { Route, withRouter } from 'react-router-dom';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
} from './NavbarElements';

class Navbar extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

        this.logout = this.logout.bind(this);
    }

    logout() {
        localStorage.clear();
        this.props.history.push('/login');
    }

    render() {
        return (
            <div style={{height: '30px' }}>
                {
                    (localStorage.getItem('USER_KEY')) && ( 
                        <Nav>
                            <Bars />

                            <NavMenu>
                                <NavLink to='/home' activeStyle>
                                    Home
                                </NavLink>
                                <NavLink to='/employees' activeStyle>
                                    Employees
                                </NavLink>
                                <NavLink to='/tasks' activeStyle>
                                    Tasks
                                </NavLink>
                                <NavLink to='/time-entries' activeStyle>
                                    Time Entry
                                </NavLink>
                            </NavMenu>

                            <div style={{ width: '30px', fontSize: "16px", float: "right", marginTop:"5px", fontWeight:'700' }}>
                                <span style={{ color: "white", cursor: "pointer" }}
                                    onClick={this.logout} >Logout</span>
                            </div>

                        </Nav>
                    )
                }
            </div>
        )
    }
}

export default withRouter(Navbar)
